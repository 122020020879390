<div class="flex place-content-between items-center">
  <div>
    <mat-icon
      [class]="mapStatusToColor[snackBarData.status]"
      class="icon w-14"
    >
      {{ mapStatusToIcon[snackBarData.status] || 'info' }}
    </mat-icon>
  </div>
  <div class="message-container">
    @if (isArrayOfMessages(snackBarData.message)) {
      <ul class="list-none px-2">
        @for (item of snackBarData.message; track item) {
          <li>
            {{ item }}
          </li>
        }
      </ul>
    } @else {
      <div
        class="px-2 whitespace-pre-line"
        [innerHTML]="snackBarData.message.toString() | translate"
      ></div>
    }
  </div>
  <div>
    <mat-icon
      class="cursor-pointer text-white"
      (click)="snackBarRef.dismiss()"
    >
      close
    </mat-icon>
  </div>
</div>
