import { Component, inject, OnInit } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { ActivationStart, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@root/app/shared/components/base/base.component';
import { SnackbarData } from '@root/app/shared/snack-bar/models/snack-bar-data';
import { SnackbarStatusEnum } from '@root/app/shared/snack-bar/models/snack-bar-status-enum';

import { takeUntil } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';

import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, TranslateModule],
})
export class SnackBarComponent extends BaseComponent implements OnInit {
  private router = inject(Router);
  public snackBarData: SnackbarData = inject(MAT_SNACK_BAR_DATA);
  public snackBarRef = inject(MatSnackBarRef<SnackBarComponent>);

  mapStatusToIcon = {
    [SnackbarStatusEnum.SUCCESS.toString()]: 'check_circle',
    [SnackbarStatusEnum.INFO.toString()]: 'info',
    [SnackbarStatusEnum.ERROR.toString()]: 'error',
    [SnackbarStatusEnum.WARNING.toString()]: 'warning',
  };

  mapStatusToColor = {
    [SnackbarStatusEnum.SUCCESS.toString()]: 'text-green',
    [SnackbarStatusEnum.INFO.toString()]: 'text-blue',
    [SnackbarStatusEnum.ERROR.toString()]: 'text-red',
    [SnackbarStatusEnum.WARNING.toString()]: 'text-orange',
  };

  ngOnInit(): void {
    this.router.events
      .pipe(takeUntil(this.destroySubject))
      .subscribe((event) => {
        if (event instanceof ActivationStart) {
          this.snackBarRef.dismiss();
        }
      });
  }

  isArrayOfMessages(value: string | string[]): value is string[] {
    return value instanceof Array;
  }
}
