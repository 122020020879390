import { SnackbarStatusEnum } from '@root/app/shared/snack-bar/models/snack-bar-status-enum';

export class SnackbarData {
  message: string | string[];
  status: SnackbarStatusEnum;
  constructor(
    message: string | string[],
    status: SnackbarStatusEnum = SnackbarStatusEnum.INFO
  ) {
    this.message = message;
    this.status = status;
  }
}
