import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarComponent } from '@root/app/shared/snack-bar/components/snack-bar/snack-bar.component';
import { SnackbarData } from '@root/app/shared/snack-bar/models/snack-bar-data';
import { SnackbarStatusEnum } from '@root/app/shared/snack-bar/models/snack-bar-status-enum';

const DURATION = 5000;
@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);

  config: MatSnackBarConfig = {
    verticalPosition: 'bottom',
    horizontalPosition: 'right',
    panelClass: SnackbarStatusEnum.SUCCESS,
  };

  success(
    message: string = this.translateService.instant('shared.successMsg')
  ) {
    const snackBarData = new SnackbarData(message, SnackbarStatusEnum.SUCCESS);
    this.open(snackBarData);
  }

  error(
    message: string | string[] = this.translateService.instant(
      'shared.errorMsg'
    )
  ) {
    const snackBarData = new SnackbarData(message, SnackbarStatusEnum.ERROR);
    this.open(snackBarData);
  }

  warning(message: string) {
    const snackBarData = new SnackbarData(message, SnackbarStatusEnum.WARNING);
    this.open(snackBarData);
  }

  info(message: string) {
    const snackBarData = new SnackbarData(message, SnackbarStatusEnum.INFO);
    this.open(snackBarData);
  }

  open(
    snackBarData: SnackbarData,
    verticalPosition = this.config.verticalPosition,
    horizontalPosition = this.config.horizontalPosition
  ) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      horizontalPosition,
      verticalPosition,
      panelClass: ['custom-snack-bar-panel', snackBarData.status],
      duration: DURATION,
      data: snackBarData,
    } as MatSnackBarConfig);
  }
}
